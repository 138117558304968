import React, { useEffect, useRef, useState } from "react";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const SearchLocationInput = ({ setSelectedLocation }) => {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        componentRestrictions: { country: "USA" }, // Optional: Restrict to specific country
      }
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const formattedAddress = addressObject.formatted_address;
    updateQuery(formattedAddress);

    // Check if geometry is available before accessing lat and lng
    if (addressObject.geometry) {
      const latLng = {
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      };

      console.log("Selected Address:", formattedAddress);
      console.log("Coordinates:", latLng);

      // Pass both formatted address and lat/lng to the parent
      setSelectedLocation(formattedAddress, latLng);
    } else {
      console.log("No geometry available for this place.");
      // If there's no geometry, still pass the formatted address to the parent
      setSelectedLocation(formattedAddress, null);
    }
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  return (
    <>
      <input
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Search here..."
        value={query}
        required
      />
    </>
  );
};

export default SearchLocationInput;