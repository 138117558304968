import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';
import Footer from '../Home/Footer';
import VerifyEmail from "./VerifyEmail.jsx"
import { validatePassword } from '../Utils/PasswordValidator.js';
import SearchLocationInput from '../Utils/SearchLocationInput.js';
import { handleClinicSignUp, sendOtp, verifyOtp } from '../../ApiHandler/AuthFunctions.js';

const ClinicSignUp = () => {
    const [formDetails, setFormDetails] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        clinicName: '',
        phoneNumber: '',
        street: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        patients: '',
    });
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [otp, setOtp] = useState(''); // OTP entered by the user
    const [otpStage, setOtpStage] = useState(false);

    // Update form details when fields are changed
    const handleChange = (e) => {
        setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
    };

    const handlePasswordChange = (password) => {
        setFormDetails({ ...formDetails, password });
        const errors = validatePassword(password);
        setPasswordErrors(errors);
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const verifyResponse = await verifyOtp(formDetails.email, otp);
        if (verifyResponse.success) {
            // Proceed with registration after OTP verification
            await handleClinicSignUp(e, formDetails, setFormDetails);
            setOtp('');
            setOtpStage(false);
        } else {
            toast.error(verifyResponse.message, {
                position: "top-center"
            });
        }
        setLoading(false);
    };

    const handleSelectedLocation = (formattedAddress) => {
        // Set the formatted address to the street field
        setFormDetails({ ...formDetails, street: formattedAddress });
    };

    return (
        <>
            <ToastContainer />
            <Navbar />
            {/* <h2>Clinic</h2> */}
            <div className="auth-container">
                {!otpStage ? (
                    <>
                        <h1>Register as Clinic</h1>
                        <p>Please enter your details</p>
                        <form onSubmit={(e) => sendOtp(e, passwordErrors, formDetails.email, formDetails.password, formDetails.confirmPassword, setOtpLoading, setOtpStage)}>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email"
                                        value={formDetails.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input
                                        type="tel"
                                        name="phoneNumber"
                                        placeholder="Enter your phone number"
                                        value={formDetails.phoneNumber}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        maxLength={13}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Enter your password"
                                        value={formDetails.password}
                                        onChange={(e) => handlePasswordChange(e.target.value)}
                                        required
                                    />
                                    {passwordErrors.length > 0 && (
                                        <ul className="password-errors">
                                            {passwordErrors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Enter your password again"
                                        value={formDetails.confirmPassword}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>Clinic Name</label>
                                    <input
                                        type="text"
                                        name="clinicName"
                                        placeholder="Enter your clinic name"
                                        value={formDetails.clinicName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Street</label>
                                    {/* <input
                                        type="text"
                                        name="street"
                                        placeholder="Enter your street"
                                        value={formDetails.street}
                                        onChange={handleChange}
                                        required
                                    /> */}
                                    <SearchLocationInput setSelectedLocation={handleSelectedLocation} />
                                    {/* {formDetails.street && <p>Selected Address: {formDetails.street}</p>} */}
                                </div>
                            </div>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>City</label>
                                    <input
                                        type="text"
                                        name="city"
                                        placeholder="Enter your city"
                                        value={formDetails.city}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Postal Code</label>
                                    <input
                                        type="text"
                                        name="postalCode"
                                        placeholder="Enter your postal code"
                                        value={formDetails.postalCode}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>State</label>
                                    <input
                                        type="text"
                                        name="state"
                                        placeholder="Enter your state"
                                        value={formDetails.state}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Country</label>
                                    <input
                                        type="text"
                                        name="country"
                                        placeholder="Enter your country"
                                        value={formDetails.country}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Estimate number of patients</label>
                                <select
                                    name="patients"
                                    value={formDetails.patients}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select</option>
                                    <option>1-50</option>
                                    <option>1-100</option>
                                    <option>1-500</option>
                                    <option>1-1000</option>
                                </select>
                            </div>
                            {/* <button type="submit" className="sign-in-button" disabled={loading}>
                        {loading ? 'Signing Up...' : 'Sign Up'}
                    </button> */}
                            <button type="submit" className="sign-in-button" disabled={otpLoading}>
                                {otpLoading ? 'Sending OTP...' : 'Sign Up'}
                            </button>
                            <p>Already have an account? <a href="/login">Log In</a></p>
                        </form>
                    </>
                ) : (
                    <VerifyEmail
                        otp={otp}
                        setOtp={setOtp}
                        loading={loading}
                        handleOtpSubmit={handleOtpSubmit}
                    />
                )}
            </div>
            <Footer />
        </>
    );
};

export default ClinicSignUp;