import { useState } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "./Navbar";
import Footer from "../Home/Footer";
import { useNavigate } from "react-router-dom";
import { handleLogin } from '../../ApiHandler/AuthFunctions.js';

function ClinicLogin() {
    const [formDetails, setFormDetails] = useState({
        email: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormDetails({
            ...formDetails,
            [e.target.name]: e.target.value
        });
    };

    return (
        <>
            <ToastContainer />
            <Navbar />
            {/* <h2>Clinic</h2> */}
            <div className="auth-container">
                <h1>Log In</h1>
                <p>Please enter your details</p>
                <form onSubmit={(e) => handleLogin(e, formDetails, navigate, setLoading)}>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            value={formDetails.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            name="password"
                            placeholder="Enter your password"
                            value={formDetails.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="sign-in-button" disabled={loading}>
                        {loading ? 'logging In...' : 'Log In'}
                    </button>
                </form>
                <p>Forgot Password? <a href="/forgot-password">Click Here</a></p>
            </div>
            <Footer />
        </>
    );
}

export default ClinicLogin;