import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateSessionId } from '../../ApiHandler/PaymentFunctions';

const PaymentSuccess = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const sessionId = query.get('session_id');

        if (!sessionId) {
            setError('Missing session ID');
            setLoading(false);
            return;
        }
        // Validate the session ID with your backend
        validateSessionId(sessionId, setSuccess, setError, setLoading);
    }, [location.search]);

    // Add this useEffect for handling error and redirecting
    useEffect(() => {
        // If error or invalid session, redirect back to home page or payment page
        if (error) {
            navigate('/'); // Redirect to an appropriate page (e.g., home or payment)
        }
    }, [error, navigate]);

    if (loading) return <div style={{ color: 'black', marginTop: '20px' }}><h2>Loading...</h2></div>;
    if (error) return <div style={{ color: 'red', marginTop: '20px' }}><h2>Something Went Wrong</h2></div>;
    if (success) return <div style={{ color: 'green', marginTop: '20px' }}> <h2> Payment method saved successfully!</h2> <button onClick={() => navigate('/dashboard')} className='save-button'>Click Here to go back to the dashboard</button> </div>;

    return <div style={{ color: 'black', marginTop: '20px' }}><h2>Failed to process payment!</h2></div>;
};

export default PaymentSuccess;