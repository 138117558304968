import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchClinics } from '../../ApiHandler/ClinicFunctions.js';
import { fetchPatientProfile, updatePatientProfile } from '../../ApiHandler/PatientFunctions';

const PatientProfile = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        shippingType: '',
        frequency: '',
        kitType: '',
        clinicId: '',
    });
    const [clinics, setClinics] = useState([]);  // State to store fetched clinics
    const [fetchedClinicId, setFetchedClinicId] = useState('');
    const [isProfileComplete, setIsProfileComplete] = useState(false);

    useEffect(() => {
        fetchPatientProfile(setFormData, setFetchedClinicId);
        fetchClinics(setClinics);
    }, []);

    useEffect(() => {
        const isComplete = Object.values(formData).every(value => value && value !== '');
        setIsProfileComplete(isComplete);
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <ToastContainer />
            <div className="patients-container">
                <div className="form-container">
                    <h2>Profile</h2>
                    {!isProfileComplete && (
                        <p style={{ color: 'red' }}>
                            Please complete your profile to be authenticated by clinics and receive your test kits.
                        </p>
                    )}
                    <form className="add-patient-form" onSubmit={(e) => updatePatientProfile(e, formData)}>
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Enter Your Name"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter Your Email"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Phone Number</label>
                            <input
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="Enter Your Phone Number"
                                required
                            />
                        </div>
                        {fetchedClinicId === 'C000' && (
                            <div className="form-group">
                                <label>Select Clinic</label>
                                <select
                                    name="clinicId"
                                    value={formData.clinicId}
                                    onChange={handleChange}
                                >
                                    <option value="">Select a Clinic</option>
                                    {clinics.map((clinic) => (
                                        <option key={clinic.clinic_id} value={clinic.clinic_id}>
                                            {clinic.clinic_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className='in-row-input'>
                            <div className="form-group">
                                <label>Street</label>
                                <input
                                    type="text"
                                    name="street"
                                    value={formData.street}
                                    onChange={handleChange}
                                    placeholder="Enter your street"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>City</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    placeholder="Enter your city"
                                    required
                                />
                            </div>
                        </div>
                        <div className='in-row-input'>
                            <div className="form-group">
                                <label>Postal Code</label>
                                <input
                                    type="text"
                                    name="postalCode"
                                    value={formData.postalCode}
                                    onChange={handleChange}
                                    placeholder="Enter your postal code"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>State</label>
                                <input
                                    type="text"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    placeholder="Enter your state"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Country</label>
                            <input
                                type="text"
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                                placeholder="Enter your country"
                                required
                            />
                        </div>
                        <div className='in-row-input'>
                            <div className="form-group">
                                <label>Shipping Type</label>
                                <select
                                    name="shippingType"
                                    value={formData.shippingType}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select</option>
                                    <option value="USPS">USPS</option>
                                    <option value="Overnight">Overnight</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Frequency</label>
                                <select
                                    name="frequency"
                                    value={formData.frequency}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select</option>
                                    <option value="3 Months">3 Months</option>
                                    <option value="1 Time">1 Time</option>
                                    <option value="1 Month">1 Month</option>
                                    <option value="Yearly">Yearly</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Kit Type</label>
                            <select
                                name="kitType"
                                value={formData.kitType}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select</option>
                                <option value="Kit 1">Kit 1</option>
                                <option value="Kit 2">Kit 2</option>
                                <option value="Kit 3">Kit 3</option>
                            </select>
                        </div>
                        <button type="submit" className="profile-save-button">Update</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default PatientProfile;