import { useState } from "react";
import { ToastContainer } from 'react-toastify';
import { useParams } from "react-router-dom";
import { handleResetPassword } from "../../ApiHandler/AuthFunctions";
import { validatePassword } from '../Utils/PasswordValidator.js';

function ResetPassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const { token } = useParams();

    const handlePasswordChange = (password) => {
        setPassword(password)
        const errors = validatePassword(password);
        setPasswordErrors(errors);
    };

    return (
        <>
            <ToastContainer />
            <div className="auth-container">
                <h1>Reset Password</h1>
                <form onSubmit={(e) => handleResetPassword(e, token, password, confirmPassword, setPassword, setConfirmPassword, passwordErrors, setLoading)}>
                    <div className="form-group">
                        <label>New Password</label>
                        <input
                            type="password"
                            placeholder="Enter new password"
                            value={password}
                            onChange={(e) => handlePasswordChange(e.target.value)}
                            required
                        />
                        {passwordErrors.length > 0 && (
                            <ul className="password-errors">
                                {passwordErrors.map((error, index) => (
                                    <li key={index}>{error}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="save-button" disabled={loading}>
                        {loading ? 'Please wait...' : 'Reset Password'}
                    </button>
                </form>
            </div>
        </>
    );
}

export default ResetPassword;