import Axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
const API_URL = process.env.REACT_APP_API_URL;
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

// Function to check if clinic has added payment details
export const checkPaymentDetails = async (setHasPaymentDetails) => {
    try {
        const response = await Axios.get(`${API_URL}/payment/check-payment-details`, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });
        if (response.status === 200) {
            setHasPaymentDetails(response.data.hasPaymentDetails);
        } else {
            console.error('Error fetching payment details');
        }
    } catch (error) {
        console.error('Error to check payment details: ', error);
    }
};

// Function to setup payment method/card
export const handlePaymentSetup = async (setLoading) => {
    try {
        setLoading(true);  // Indicate loading state
        const response = await Axios.post(`${API_URL}/payment/create-checkout-session`, {}, {
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        });

        if (response.status === 200) {
            const stripe = await stripePromise;
            await stripe.redirectToCheckout({ sessionId: response.data.sessionId });
        } else {
            console.error('Error creating Stripe checkout session');
        }
    } catch (error) {
        console.error('Error in API request:', error);
    } finally {
        setLoading(false);
    }
};

// Function to validate payment session ID
export const validateSessionId = async (sessionId, setSuccess, setError, setLoading) => {
    try {
        const response = await Axios.get(`${API_URL}/payment/payment-success?session_id=${sessionId}`);

        if (response.status === 200 && response.data.success) {
            setSuccess(true);
        } else {
            setError('Failed to verify payment session');
        }
    } catch (error) {
        setError('Error verifying payment session');
    } finally {
        setLoading(false);
    }
};
