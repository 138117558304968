import React, { useState } from 'react';
import logo from '../../Assets/logo.png';

const Navbar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const handleSidebarToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <>
            <div className="nav-bg">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <img className="navbar-brand logo" src={logo} alt="logo" />
                        <h2 className="main-heading">QuickCare</h2>
                        <button className="menu-button" onClick={handleSidebarToggle} aria-label="Toggle sidebar">&#9776;</button>
                        <div className='nav-links collapse navbar-collapse' id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="/clinic-signup">Clinic</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="/patient-signup">Patient</a>
                                </li>
                                <li>
                                    <a className="nav-link contact-link" aria-current="page" href="#connect">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>

            <div className={`home-sidebar ${sidebarOpen ? 'home-sidebar-open' : ''}`}>
                <div className='home-sidebar-header'>
                    <img className="navbar-brand logo" src={logo} alt="logo" />
                    <h2 className="main-heading">QuickCare</h2>
                    <button className="close-btn" onClick={handleSidebarToggle}>&times;</button>
                </div>
                <ul className="home-sidebar-nav">
                    <li className="nav-item">
                        <a className="nav-link" href="/clinic-signup">Clinic</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/patient-signup">Patient</a>
                    </li>
                    <li>
                        <a className="nav-link contact-link" href="#connect" onClick={handleSidebarToggle}>Contact Us</a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Navbar;