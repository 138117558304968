import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleAddLab, handleUpdateLab, fetchLabs } from '../../ApiHandler/ClinicFunctions';

const AddLab = () => {
    const [labData, setLabData] = useState({
        labName: '',
        labId: '',
        labPhoneNo: '',
        labStreet: '',
        LabCity: '',
        labPostalCode: '',
        labState: '',
        labCountry: '',
        instructions: '',
    });
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false); // State to track if updating

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLabData({
            ...labData,
            [name]: value,
        });
    };

    useEffect(() => {
        fetchLabs(setLabData, setIsUpdate); // Fetch labs and determine if update mode
    }, []);

    const handleSubmit = (e) => {
        if (isUpdate) {
            handleUpdateLab(e, labData, setLoading);
        } else {
            handleAddLab(e, labData, setLoading);
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="add-patient-form">
                <h3>{isUpdate ? "Update Lab Data" : "Add Lab Data"}</h3>
                <div className="form-group">
                    <label>Lab Name</label>
                    <input
                        type="text"
                        name="labName"
                        value={labData.labName}
                        onChange={handleChange}
                        placeholder="Enter Lab Name"
                    />
                </div>
                <div className="form-group">
                    <label>Lab Identification Number</label>
                    <input
                        type="text"
                        name="labId"
                        value={labData.labId}
                        onChange={handleChange}
                        placeholder="Enter Lab ID"
                    />
                </div>
                <div className="form-group">
                    <label>Phone Number</label>
                    <input
                        type="text"
                        name="labPhoneNo"
                        value={labData.labPhoneNo}
                        onChange={handleChange}
                        placeholder="Enter Phone Number"
                    />
                </div>
                <div className="form-group">
                    <label>Street</label>
                    <input
                        type="text"
                        name="labStreet"
                        value={labData.labStreet}
                        onChange={handleChange}
                        placeholder="Enter Street"
                    />
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>City</label>
                        <input
                            type="text"
                            name="LabCity"
                            value={labData.LabCity}
                            onChange={handleChange}
                            placeholder="Enter City"
                        />
                    </div>
                    <div className="form-group">
                        <label>Postal Code</label>
                        <input
                            type="text"
                            name="labPostalCode"
                            value={labData.labPostalCode}
                            onChange={handleChange}
                            placeholder="Enter Postal Code"
                        />
                    </div>
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>State</label>
                        <input
                            type="text"
                            name="labState"
                            value={labData.labState}
                            onChange={handleChange}
                            placeholder="Enter State"
                        />
                    </div>
                    <div className="form-group">
                        <label>Country</label>
                        <input
                            type="text"
                            name="labCountry"
                            value={labData.labCountry}
                            onChange={handleChange}
                            placeholder="Enter Country"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Sample Drop Off Instructions</label>
                    <textarea
                        type="text"
                        name="instructions"
                        value={labData.instructions}
                        onChange={handleChange}
                        placeholder="Enter Sample Drop Off Instructions"
                    />
                </div>
                <button
                    type="submit"
                    className="profile-save-button"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {isUpdate ? (loading ? 'Updating...' : 'Update') : (loading ? 'Saving...' : 'Save')}
                </button>

            </div>
        </>
    );
};

export default AddLab;