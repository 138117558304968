import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchPateintsDetails, updatePatientsAuthorization } from '../../ApiHandler/PatientFunctions';
import { checkPaymentDetails } from '../../ApiHandler/PaymentFunctions';
// import { handleOrderKits } from '../../ApiHandler/OrderFunctions';

const Authenticate = () => {
    const [patients, setPatients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [hasPaymentDetails, setHasPaymentDetails] = useState(false); // Check if clinic has payment details
    const [loading, setLoading] = useState(false);

    // Check if clinic has saved payment details on component load
    useEffect(() => {
        checkPaymentDetails(setHasPaymentDetails);
    }, []);

    // Handle search input change
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter patients based on search term
    const filteredPatients = patients.filter(patient =>
        patient.patient_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.patient_email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Toggle checkbox for all patients
    const handleSelectAll = () => {
        const newStatus = !selectAll;
        setSelectAll(newStatus);
        setPatients((prevPatients) =>
            prevPatients.map((patient) => ({ ...patient, isChecked: newStatus }))
        );
    };

    // Handle individual checkbox
    const handleCheckboxChange = (patientId) => {
        setPatients((prevPatients) =>
            prevPatients.map((patient) =>
                patient.patient_id === patientId
                    ? { ...patient, isChecked: !patient.isChecked }
                    : patient
            )
        );
    };

    // Authenticate selected patients
    const handleAuthenticate = async () => {
        const selectedPatients = patients.filter((patient) => patient.isChecked);
        const selectedIds = selectedPatients.map((patient) => patient.patient_id);

        try {
            await updatePatientsAuthorization(selectedIds, true, setPatients, setLoading); // true for authenticate
            // After successful authentication, set authorized status to 1
            setPatients((prevPatients) =>
                prevPatients.map((patient) =>
                    selectedIds.includes(patient.patient_id)
                        ? { ...patient, authorized: 1 }
                        : patient
                )
            );
        } catch (error) {
            console.error('Error updating patients:', error);
        }
    };

    useEffect(() => {
        fetchPateintsDetails(setPatients);
    }, []);

    return (
        <>
            <ToastContainer />
            <div className="patients-container">
                <div className="add-patient-container">
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search Patients"
                            value={searchTerm}
                            onChange={handleSearch}
                            className="search-input"
                        />
                    </div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    <th>Patient Name</th>
                                    <th>Patient Email</th>
                                    <th>Patient Phone</th>
                                    <th>Patient Address</th>
                                    <th>Shipping Type</th>
                                    <th>Frequency</th>
                                    <th>Kit Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredPatients.map((patient) => (
                                    <tr key={patient.patient_id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={patient.isChecked || false}
                                                onChange={() => handleCheckboxChange(patient.patient_id)}
                                            />
                                        </td>
                                        <td>{patient.patient_name}</td>
                                        <td>{patient.patient_email}</td>
                                        <td>{patient.patient_phone}</td>
                                        <td>{patient.patient_street}</td>
                                        <td>{patient.shipping_type}</td>
                                        <td>{patient.frequency}</td>
                                        <td>{patient.kit_type}</td>
                                        <td>
                                            <span
                                                className="status-dot"
                                                style={{
                                                    backgroundColor: patient.authorized ? 'green' : 'red',
                                                    height: '10px',
                                                    width: '10px',
                                                    borderRadius: '50%',
                                                    display: 'inline-block',
                                                    marginRight: '10px',
                                                }}
                                            ></span>
                                            {patient.authorized === 1 ? (
                                                <span className='not-authorized'>Authorized</span>
                                            ) : (
                                                <span className='not-authorized'>Not Authorized</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='in-row-input'>
                        <button className="save-button" onClick={handleAuthenticate} disabled={loading}>
                            {loading ? 'Authenticating...' : 'Authenticate'}
                        </button>
                        {hasPaymentDetails ? (
                            <button
                                style={{ backgroundColor: 'green' }}
                                className="save-button"
                                onClick={() => {
                                    const confirmed = window.confirm("Are you sure you want to place the order?");
                                    if (confirmed) {
                                        console.log("order Placed");
                                        // handleOrderKits(patients, setLoading);
                                    }
                                }}
                                disabled={!hasPaymentDetails || loading}
                            >
                                {loading ? 'Placing Order...' : 'Place Order Kits'}
                            </button>
                        ) : (
                            <p style={{ color: 'red' }} className="warning">Please add your card details before placing orders</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Authenticate;