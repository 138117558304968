import React, { useState, useEffect } from "react";
import './dashboard.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Patients from './Patients';
import Authenticate from './Authenticate';
import Reporting from './Reporting';
import ClinicProfile from './ClinicProfile';
import KitsInfo from './KitsInfo';
import PatientProfile from './PatientProfile';
import SupportInfo from './SupportInfo';
import { verifyUser } from '../../ApiHandler/AuthFunctions';
import { useNavigate } from "react-router-dom"; // Import useNavigate

function Dashboard() {
  const initialState = {
    patients: false,
    authenticate: false,
    reporting: false,
    clinicProfile: false,
    kitsInfo: false,
    patientProfile: false,
    supportInfo: false,
  };

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [auth, setAuth] = useState(false);
  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const [activeSection, setActiveSection] = useState(initialState);
  const [loading, setLoading] = useState(true); // Add loading state

  const navigate = useNavigate(); // Use navigate to redirect

  // First effect to verify user and set auth, username, and role
  useEffect(() => {
    const fetchData = async () => {
      try {
        await verifyUser(setAuth, setUserName, setRole);
      } catch (error) {
        console.error("Error verifying user:", error);
      } finally {
        setLoading(false); // Authentication check is done, set loading to false
      }
    };
    fetchData();
  }, []);

  // Second effect to handle role change and set default active section
  useEffect(() => {
    if (!loading && !auth) {
      // If loading is complete and user is not authenticated, redirect to login
      navigate("/login");
    } else if (auth) {
      const savedSection = localStorage.getItem("activeSection");

      if (savedSection) {
        setActiveSection({ ...initialState, [savedSection]: true });
      } else {
        if (role === '0') {
          setActiveSection({ ...initialState, patientProfile: true });
        } else if (role === '1') {
          setActiveSection({ ...initialState, patients: true });
        }
      }
    }
  }, [auth, role, loading, navigate]); // Trigger this effect when auth, role, or loading changes

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleClick = (option) => {
    setActiveSection({ ...initialState, [option]: true });
    localStorage.setItem("activeSection", option);  // Save active section in localStorage
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while checking authentication
  }

  return (
    <div className={`grid-container app-container ${isSidebarOpen ? 'sidebar-open' : ''}`}>
      <Header OpenSidebar={toggleSidebar} userName={userName} role={role} handleClick={handleClick} />
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        role={role}
        handleClick={handleClick}
      />
      <main className="content">
        {activeSection.patients && <Patients />}
        {activeSection.authenticate && <Authenticate />}
        {activeSection.reporting && <Reporting />}
        {activeSection.clinicProfile && <ClinicProfile />}
        {activeSection.kitsInfo && <KitsInfo />}
        {activeSection.patientProfile && <PatientProfile />}
        {activeSection.supportInfo && <SupportInfo />}
      </main>
    </div>
  );
}

export default Dashboard;