import React, { useState, useEffect } from 'react';
import AddLab from './AddLab';
import EditProfile from './EditProfile';

const Profile = () => {
    const [activeSidebarOption, setActiveSidebarOption] = useState(localStorage.getItem('activeSidebarOption') || 'profile');

    useEffect(() => {
        localStorage.setItem('activeSidebarOption', activeSidebarOption);
    }, [activeSidebarOption]);

    return (
        <div className="patients-container">
            <div className="form-container">
                <h2>Clinic Profile</h2>
                <div className="kit-selection-buttons">
                    <button
                        type="button"
                        className={`customized-kit-button ${activeSidebarOption === 'profile' ? 'active-button' : ''}`}
                        onClick={() => setActiveSidebarOption('profile')}
                    >
                        Profile
                    </button>
                    <button
                        type="button"
                        className={`standard-kit-button ${activeSidebarOption === 'lab' ? 'active-button' : ''}`}
                        onClick={() => setActiveSidebarOption('lab')}
                    >
                        Sample Testing Lab
                    </button>
                </div>
                {activeSidebarOption === 'profile' && (
                    <EditProfile />
                )}

                {activeSidebarOption === 'lab' && (
                    <AddLab />
                )}

                {/* <form className="add-patient-form">
                        <div className='profile-radio-button'>
                            <label>
                                <input
                                    type="radio"
                                    name="labType"
                                    value="userExisting"
                                    checked={labType === 'userExisting'}
                                    onChange={() => setLabType('userExisting')}
                                />
                                Existing Labs
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="labType"
                                    value="partnerLab"
                                    checked={labType === 'partnerLab'}
                                    onChange={() => setLabType('partnerLab')}
                                />
                                Add Partner Lab
                            </label>
                        </div>

                        {labType === 'partnerLab' && (
                            <AddLab header="Lab 1" />
                        )}

                        {labType === 'userExisting' && (
                            <ExistingLabs />
                        )}
                    </form> */}
            </div>
        </div>
    );
};

export default Profile;