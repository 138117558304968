import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleAddPatients } from '../../ApiHandler/PatientFunctions';
import { importPatientsFromExcel } from '../Utils/ImportPatientsExcel';

const AddPatients = () => {
  const [patients, setPatients] = useState(() => {
    const savedPatients = localStorage.getItem('patients');
    return savedPatients
      ? JSON.parse(savedPatients)
      : Array.from({ length: 10 }, () => ({
        name: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        shippingType: '',
        frequency: '',
        kitType: '',
        isChecked: false,
      }));
  });
  const [loading, setLoading] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const tableRef = useRef(null);
  const fileInputRef = React.useRef(null);

  useEffect(() => {
    localStorage.setItem('patients', JSON.stringify(patients));
  }, [patients]);

  const handleInputChange = (index, field, value) => {
    const newPatients = [...patients];
    newPatients[index] = { ...newPatients[index], [field]: value };
    setPatients(newPatients);
  };

  const handleAddRow = () => {
    setPatients([
      ...patients,
      {
        name: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        shippingType: '',
        frequency: '',
        kitType: '',
        isChecked: false,
      },
    ]);
    setTimeout(() => {
      tableRef.current.scrollTop = tableRef.current.scrollHeight;
    }, 100);
  };

  const handleRemoveRow = (index) => {
    setPatients(patients.filter((_, i) => i !== index));
  };

  const toggleAddressFields = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger hidden file input when the button is clicked
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      importPatientsFromExcel(file, setPatients);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="table-container" ref={tableRef}>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          accept=".xlsx, .xls"
        />
        <button style={{ marginBottom: '10px' }} onClick={handleButtonClick} className="save-button">
          Import Patients from File
        </button>
        <table>
          <thead>
            <tr>
              <th>Patient Name</th>
              <th>Patient Email</th>
              <th>Patient Phone</th>
              <th>Patient Address</th>
              <th>Shipping Type</th>
              <th>Frequency</th>
              <th>Kit Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {patients.map((patient, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      value={patient.name}
                      onClick={() => toggleAddressFields(null)}
                      onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      value={patient.email}
                      onClick={() => toggleAddressFields(null)}
                      onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      placeholder="Enter Phone No"
                      value={patient.phone}
                      onClick={() => toggleAddressFields(null)}
                      onChange={(e) => handleInputChange(index, 'phone', e.target.value)}
                      required
                    />
                  </td>
                  <td className="address-container">
                    <input
                      type="text"
                      placeholder="Enter Street"
                      value={patient.street}
                      onChange={(e) => handleInputChange(index, 'street', e.target.value)}
                      onClick={() => toggleAddressFields(index)}
                      required
                    />
                    {expandedRow === index && (
                      <div className="address-fields">
                        <input
                          type="text"
                          placeholder="City"
                          value={patient.city}
                          onChange={(e) => handleInputChange(index, 'city', e.target.value)}
                        />
                        <input
                          type="text"
                          placeholder="Postal Code"
                          value={patient.postalCode}
                          onChange={(e) => handleInputChange(index, 'postalCode', e.target.value)}
                        />
                        <input
                          type="text"
                          placeholder="State"
                          value={patient.state}
                          onChange={(e) => handleInputChange(index, 'state', e.target.value)}
                        />
                        <input
                          type="text"
                          placeholder="Country"
                          value={patient.country}
                          onChange={(e) => handleInputChange(index, 'country', e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    <select
                      value={patient.shippingType}
                      onClick={() => toggleAddressFields(null)}
                      onChange={(e) => handleInputChange(index, 'shippingType', e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="USPS">USPS</option>
                      <option value="Overnight">Overnight</option>
                    </select>
                  </td>
                  <td>
                    <select
                      value={patient.frequency}
                      onClick={() => toggleAddressFields(null)}
                      onChange={(e) => handleInputChange(index, 'frequency', e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="3 Months">3 Months</option>
                      <option value="1 Time">1 Time</option>
                      <option value="1 Month">1 Month</option>
                      <option value="Yearly">Yearly</option>
                    </select>
                  </td>
                  <td>
                    <select
                      value={patient.kitType}
                      onClick={() => toggleAddressFields(null)}
                      onChange={(e) => handleInputChange(index, 'kitType', e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Kit 1">Kit 1</option>
                      <option value="Kit 2">Kit 2</option>
                      <option value="Kit 3">Kit 3</option>
                    </select>
                  </td>
                  <td>
                    <button className="remove-button" onClick={() => handleRemoveRow(index)}>
                      <i className="bx bx-trash"></i>
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="buttons-container">
        <button type="submit" className="save-button" onClick={(e) => handleAddPatients(e, patients, setPatients, setLoading)} disabled={loading}>
          {loading ? 'Saving...' : 'Save All'}
        </button>
        <button className="add-button" onClick={handleAddRow}>
          <i className="bx bx-plus"></i>
        </button>
      </div>
    </>
  );
};

export default AddPatients;