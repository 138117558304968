import React, { useState, useEffect } from 'react';
import AddPatients from './AddPatients';
import UpdatePatients from './UpdatePatients';

function Home() {
    const [activeButton, setActiveButton] = useState(localStorage.getItem('activeButton') || 'customized');
    useEffect(() => {
        localStorage.setItem('activeButton', activeButton);
    }, [activeButton]);
    return (
        <div className='patients-container'>
            <div className='add-patient-container'>
                <div className="kit-selection-buttons">
                    <button
                        type="button"
                        className={`customized-kit-button ${activeButton === 'customized' ? 'active-button' : ''}`}
                        onClick={() => setActiveButton('customized')}
                    >
                        Add Patients
                    </button>
                    <button
                        type="button"
                        className={`standard-kit-button ${activeButton === 'standard' ? 'active-button' : ''}`}
                        onClick={() => setActiveButton('standard')}
                    >
                        Update Patients
                    </button>
                </div>
                {activeButton === 'customized' && (
                    <AddPatients />
                )}
                {activeButton === 'standard' && (
                    <UpdatePatients />
                )}
            </div>
        </div>
    )
}

export default Home;
