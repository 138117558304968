import * as XLSX from 'xlsx';

// This function will handle the import and parsing of the Excel file
export const importPatientsFromExcel = (file, setPatients) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    // Converting the sheet data to JSON
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: ['name', 'email', 'phone', 'street', 'city', 'postalCode', 'state', 'country', 'shippingType', 'frequency', 'kitType'],
      defval: '',  
    }).slice(1);

    // We can now map the jsonData to match the patients array structure
    const importedPatients = jsonData.map((row) => ({
      name: row.name,
      email: row.email,
      phone: row.phone,
      street: row.street,
      city: row.city,
      postalCode: row.postalCode,
      state: row.state,
      country: row.country,
      shippingType: row.shippingType,
      frequency: row.frequency,
      kitType: row.kitType,
      isChecked: false,
    }));

    // Update the patients state with the imported data
    setPatients(importedPatients);
  };

  reader.readAsArrayBuffer(file);
};