import React from 'react';
import ReactDOM from "react-dom";
import Home from '../src/Components/Home/Home';
import PaymentSuccess from './Components/Payment/PaymentSuccess';
import Login from '../src/Components/Auth/Login';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import ClinicSignUp from '../src/Components/Auth/ClinicSignUp';
import PatientSignUp from '../src/Components/Auth/PatientSignUp';
import Dashboard from '../src/Components/Dashboard/Dashboard';
import './index.css';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />
    },
    {
        path: "/payment-success",
        element: <PaymentSuccess />
    },
    {
        path: "/patient-signup",
        element: <PatientSignUp />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />
    },
    {
        path: "/reset-password/:token",
        element: <ResetPassword />
    },
    {
        path: "/clinic-signup",
        element: <ClinicSignUp />
    },
    {
        path: "/dashboard",
        element: <Dashboard />
    },
]);

ReactDOM.render(
    <RouterProvider router={router} />,
    document.getElementById("root")
);