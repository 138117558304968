import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';  
import { BsJustify } from 'react-icons/bs';
import { FaCaretDown } from 'react-icons/fa';
import profileIcon from '../../Assets/student-profile.png';
import { checkPaymentDetails, handlePaymentSetup } from '../../ApiHandler/PaymentFunctions';

function Header({ OpenSidebar, userName, role, handleClick }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hasPaymentDetails, setHasPaymentDetails] = useState(false); // To track if payment details exist
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkPaymentDetails(setHasPaymentDetails);
  }, []);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    handleClick(option);
    toggleDropdown();
  };

  return (
    <header className="dash-header">
      <div className="menu-icon">
        <BsJustify className="dash-icon" onClick={OpenSidebar} />
      </div>
      <div className="header-left"></div>
      <div className="header-right">
        <p>Hey, {userName}</p>
        <div className="profile-container">
          <img className="profile-icon" src={profileIcon} alt="" />
          {role == '1' && (<FaCaretDown className="dropdown-icon" onClick={toggleDropdown} />)}
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <ul>
                <li onClick={() => handleOptionClick('clinicProfile')}>
                  <label>Profile</label>
                </li>
                <li onClick={() => handlePaymentSetup(setLoading)} disabled={loading}>
                  <label>{loading ? 'Redirecting to Stripe...' : hasPaymentDetails ? 'Edit Card Details' : 'Billing'}</label>
                </li>
                <li onClick={() => handleOptionClick('kitsInfo')}>
                  <label>Kits Information</label>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
