import logo from '../../Assets/logo.png'

const Navbar = () => {
    return (
        <>
            <div class="nav-bg">
                <nav class="navbar navbar-expand-lg">
                    <div class="container-fluid">
                        <img class="navbar-brand logo" src={logo} alt=""></img>
                        <h2 class="main-heading">Quick Care</h2>
                        <ul class="navbar-nav ms-auto">
                            <li>
                                <a class="nav-link contact-link" aria-current="page" href="/">Home</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar