import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchPateintsDetails, handleUpdatePatient } from "../../ApiHandler/PatientFunctions"

const UpdatePatients = () => {
  const [patients, setPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingPatientId, setEditingPatientId] = useState(null);
  const [editDetails, setEditDetails] = useState({});
  const [expandedRow, setExpandedRow] = useState(null);

  // Handle search input change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPatients = patients.filter(patient =>
    patient.patient_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    patient.patient_email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Start editing a patient
  const startEditing = (patient) => {
    setEditingPatientId(patient.patient_id);
    setEditDetails(patient);
  };

  // Handle input changes in the edit mode
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditDetails((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const toggleAddressFields = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  useEffect(() => {
    fetchPateintsDetails(setPatients);
  }, []);


  return (
    <>
      <ToastContainer />
      <div className="search-container">
        <input
          type="text"
          placeholder="Search Patients"
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>SNO</th>
              <th>Patient Name</th>
              <th>Patient Email</th>
              <th>Patient Phone</th>
              <th>Patient Address</th>
              <th>Shipping Type</th>
              <th>Frequency</th>
              <th>Kit Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredPatients.map((patient, index) => (
              <tr key={patient.id}>
                <td>{index + 1}</td>
                {editingPatientId === patient.patient_id ? (
                  <>
                    <td>
                      <input
                        type="text"
                        name="patient_name"
                        value={editDetails.patient_name}
                        onChange={handleEditChange}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="email"
                        name="patient_email"
                        value={editDetails.patient_email}
                        onChange={handleEditChange}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="tel"
                        name="patient_phone"
                        value={editDetails.patient_phone}
                        onChange={handleEditChange}
                        required
                      />
                    </td>
                    <td className='address-container'>
                      <input
                        type="text"
                        name="patient_street"
                        value={editDetails.patient_street}
                        onChange={handleEditChange}
                        onClick={() => toggleAddressFields(index)}
                        required
                      />
                      {expandedRow === index && (
                        <div className="address-fields">
                          <input
                            type="text"
                            name="patient_city"
                            value={editDetails.patient_city}
                            onChange={handleEditChange}
                            required
                          />
                          <input
                            type="text"
                            name="patient_postal_code"
                            value={editDetails.patient_postal_code}
                            onChange={handleEditChange}
                            required
                          />
                          <input
                            type="text"
                            name="patient_state"
                            value={editDetails.patient_state}
                            onChange={handleEditChange}
                            required
                          />
                          <input
                            type="text"
                            name="patient_country"
                            value={editDetails.patient_country}
                            onChange={handleEditChange}
                            required
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      <select
                        name="shipping_type"
                        value={editDetails.shipping_type}
                        onChange={handleEditChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="USPS">USPS</option>
                        <option value="Overnight">Overnight</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="frequency"
                        value={editDetails.frequency}
                        onChange={handleEditChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="3 Months">3 Months</option>
                        <option value="1 Time">1 Time</option>
                        <option value="1 Month">1 Month</option>
                        <option value="Yearly">Yearly</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="kit_type"
                        value={editDetails.kit_type}
                        onChange={handleEditChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Kit-1">Kit 1</option>
                        <option value="Kit-2">Kit 2</option>
                        <option value="Kit-3">Kit 3</option>
                      </select>
                    </td>
                    <td>
                      <button
                        className="update-button"
                        onClick={() => handleUpdatePatient(patient.patient_id, editDetails, setPatients, setEditingPatientId)}
                      >
                        Save
                      </button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{patient.patient_name}</td>
                    <td>{patient.patient_email}</td>
                    <td>{patient.patient_phone}</td>
                    <td>{patient.patient_street}</td>
                    <td>{patient.shipping_type}</td>
                    <td>{patient.frequency}</td>
                    <td>{patient.kit_type}</td>
                    <td>
                      <button
                        className="update-button"
                        onClick={() => startEditing(patient)}
                      >
                        Edit
                      </button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UpdatePatients;