import Axios from 'axios';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL;

// Function to send otp for email verification
export const sendOtp = async (e, passwordErrors, email, password, confirmPassword, setOtpLoading, setOtpStage) => {
    e.preventDefault();
    if (passwordErrors.length > 0) {
        return;
    }
    // Check if the password and confirm password match
    if (password !== confirmPassword) {
        toast.error("Passwords do not match", {
            position: "top-center"
        });
        return;
    }
    try {
        setOtpLoading(true);
        const response = await Axios.post(`${API_URL}/auth/send-otp`, { email });
        if (response.status === 200) {
            toast.success("OTP sent to your email!", {
                position: "top-center"
            });
            setOtpStage(true);
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            toast.error("Email is already registered", {
                position: "top-center"
            });
        } else {
            toast.error("Error sending OTP. Please try again later!", {
                position: "top-center"
            });
        }
        console.error('Error sending OTP', error);
    } finally {
        setOtpLoading(false);
    }
};

// Function to verify otp sent to email
export const verifyOtp = async (email, otp) => {
    try {
        const response = await Axios.post(`${API_URL}/auth/verify-otp`, { email, otp });
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error verifying OTP', error);
        return { success: false, message: 'Invalid OTP, Please try again.' };
    }
};

// Function for clinic signup
export const handleClinicSignUp = async (e, formDetails, setFormDetails) => {
    e.preventDefault();
    try {
        const response = await Axios.post(`${API_URL}/auth/clinic-signup`, {
            email: formDetails.email,
            password: formDetails.password,
            clinicName: formDetails.clinicName,
            street: formDetails.street,
            city: formDetails.city,
            postalCode: formDetails.postalCode,
            state: formDetails.state,
            country: formDetails.country,
            phoneNumber: formDetails.phoneNumber,
            patients: formDetails.patients,
        });

        if (response.status === 201) {
            toast.success("You are registered successfully", {
                position: "top-center"
            });
            setFormDetails({
                email: '',
                password: '',
                confirmPassword: '',
                clinicName: '',
                phoneNumber: '',
                street: '',
                city: '',
                postalCode: '',
                state: '',
                country: '',
                patients: '',
            });
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            toast.error("Email is already registered", {
                position: "top-center"
            });
        } else {
            toast.error("Server error. Please try again later", {
                position: "top-center"
            });
        }
        console.error('Error during signup:', error);
    }
};

// Function for patient signup
export const handlePatientSignUp = async (e, formDetails, setFormDetails) => {
    e.preventDefault();
    try {
        // Send signup request to the server
        const response = await Axios.post(`${API_URL}/auth/patient-signup`, {
            firstName: formDetails.firstName,
            lastName: formDetails.lastName,
            email: formDetails.email,
            phone: formDetails.phone,
            password: formDetails.password,
            insuranceOption: formDetails.insuranceOption,
            clinicId: formDetails.clinicId
        });

        if (response.status === 201) {
            toast.success("You are registered successfully", {
                position: "top-center"
            });

            // Reset the form fields after successful registration
            setFormDetails({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                password: '',
                confirmPassword: '',
                insuranceOption: ''
            });
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            toast.error("Email is already registered", {
                position: "top-center"
            });
        } else {
            toast.error("Server error. Please try again later", {
                position: "top-center"
            });
        }
        console.error('Error during signup:', error);
    }
};

// Function for clinics and patients to login
export const handleLogin = async (e, formDetails, navigate, setLoading) => {
    e.preventDefault();
    try {
        setLoading(true);
        const response = await Axios.post(`${API_URL}/auth/login`, {
            email: formDetails.email,
            password: formDetails.password,
        });

        if (response.status === 200) {
            const { token } = response.data;
            localStorage.clear();
            localStorage.setItem('token', token);
            toast.success("Login successful", {
                position: "top-center"
            });

            navigate('/dashboard');
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            toast.error("Invalid email or password", {
                position: "top-center"
            });
        } else {
            toast.error("Server error. Please try again later", {
                position: "top-center"
            });
            console.error('Error during login:', error);
        }
    } finally {
        setLoading(false);
    }
};

// Function to logout
export const logout = async (navigate) => {
    const token = localStorage.getItem("token");
    if (token) {
        await Axios.post(`${API_URL}/auth/logout`, {}, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                if (response.status === 200) {
                    localStorage.clear();
                    navigate("/login");
                }
            })
            .catch(err => console.log(err));
    } else {
        localStorage.clear();
        navigate("/login");
    }
};

// Function to send a reset password link to user's email
export const forgotPassword = async (e, email, setLoading, setEmail) => {
    e.preventDefault();
    try {
        setLoading(true);
        const response = await Axios.post(`${API_URL}/auth/forgot-password`, { email }, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });
        if (response.status === 200) {
            toast.success("If the email is registered, a verification link has been sent!", {
                position: 'top-center',
            });
            setEmail('');
        } else {
            toast.error('An Error Occured, please try again later!', {
                position: 'top-center',
            });
        }
    } catch (error) {
        if (error.response && error.response.status === 404) {
            toast.success("If the email is registered, a verification link has been sent!", {
                position: 'top-center',
            });
            setEmail('');
        } else {
            console.error('Error sending reset link:', error);
            toast.error('Server error, please try again later', {
                position: 'top-center',
            });
        }
    } finally {
        setLoading(false);
    }
};

// Function to reset user's password
export const handleResetPassword = async (e, token, password, confirmPassword, setPassword, setConfirmPassword, passwordErrors, setLoading) => {
    e.preventDefault();
    if (passwordErrors.length > 0) {
        return;
    }
    if (password !== confirmPassword) {
        toast.error("Passwords do not match", {
            position: "top-center"
        });
        return;
    }
    try {
        setLoading(true);
        const response = await Axios.post(`${API_URL}/auth/reset-password/${token}`, { password }, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });
        if (response.status === 200) {
            toast.success(response.data.message, {
                position: 'top-center',
            });
            setPassword('');
            setConfirmPassword('');
        } else {
            toast.error('An Error Occured, please try again later!', {
                position: 'top-center',
            });
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            toast.error("Invalid or expired link", {
                position: 'top-center',
            });
        } else {
            console.error('Error in resetting password:', error);
            toast.error('Server error, please try again later', {
                position: 'top-center',
            });
        }
    } finally {
        setLoading(false);
    }
};

// Function to verify user using token
export const verifyUser = async (setAuth, setUserName, setRole) => {
    try {
        const token = localStorage.getItem("token");
        const response = await Axios.get(`${API_URL}/auth/verify-user`, {
            headers: {
                Authorization: token
            }
        });

        if (response.data.status === "success") {
            setAuth(true);
            setUserName(response.data.user_name);
            setRole(response.data.role_id);
        } else {
            setAuth(false);
        }
    } catch (err) {
        console.error("Error verifying user:", err);
        setAuth(false);
    }
};