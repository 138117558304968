import React, { useState } from 'react';

const KitsInfo = () => {
    const [activeKit, setActiveKit] = useState('kit1');

    const renderKitInfo = () => {
        switch (activeKit) {
            case 'kit1':
                return (
                    <div className="kit-section">
                        <h2>Kit 1 - Common STDs</h2>
                        <p>At-home STI testing kits sample collection for gonorrhea, chlamydia & HIV.</p>
                        <h3>Gonorrhea and Chlamydia Testing Sample Collection (Urine, Oral Swab & Rectal Swab)</h3>
                        <ul>
                            <li><strong>Urine Collection Kit:</strong>
                                <ul>
                                    <li>Sterile urine collection cup with a secure, leak-proof lid.</li>
                                    <li>Urine transfer pipette or straw.</li>
                                    <li>Collection tube with preservative if needed.</li>
                                    <li>Absorbent pad to prevent leaks in case of spills.</li>
                                    <li>Biohazard bag with a zip-lock seal.</li>
                                </ul>
                            </li>
                            <li><strong>Rectal Swab Collection Kit:</strong>
                                <ul>
                                    <li>Rectal swab with collection pad.</li>
                                    <li>Sterile swab (vaginal, rectal sample).</li>
                                    <li>Vial with transport solution or container to store the swab.</li>
                                    <li>Transport tube containing transport medium.</li>
                                    <li>Label for patient identification.</li>
                                </ul>
                            </li>
                            <li><strong>Oral Swab Collection Kit:</strong>
                                <ul>
                                    <li>Oral swab with collection pad.</li>
                                    <li>Vial with transport solution or container to store the swab.</li>
                                    <li>Label for patient identification and sample.</li>
                                </ul>
                            </li>
                        </ul>

                        <h3>HIV Testing - DBS Test HIV ½ Antigen/Antibody</h3>
                        <ul>
                            <li><strong>Fingerstick Blood Collection Kit:</strong>
                                <ul>
                                    <li>Sterile lancets for finger pricking.</li>
                                    <li>Blood collection card or small blood collection tube.</li>
                                    <li>Alcohol swabs for disinfection.</li>
                                    <li>Bandage.</li>
                                    <li>Desiccant pack if using a dried blood spot card.</li>
                                    <li>Biohazard bag with a zip-lock seal.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                );
            case 'kit2':
                return (
                    <div className="kit-section">
                        <h2>Kit 2 - Common STDs + Syphilis</h2>
                        <p>At-home STI testing kits sample collection for gonorrhea, chlamydia, syphilis & HIV.</p>

                        <h3>Gonorrhea and Chlamydia Testing Sample Collection (Urine, Oral Swab & Rectal Swab)</h3>
                        <ul>
                            <li><strong>Urine Collection Kit:</strong>
                                <ul>
                                    <li>Sterile urine collection cup with a secure, leak-proof lid.</li>
                                    <li>Urine transfer pipette or straw.</li>
                                    <li>Collection tube with preservative if needed.</li>
                                    <li>Absorbent pad to prevent leaks in case of spills.</li>
                                    <li>Biohazard bag with a zip-lock seal.</li>
                                </ul>
                            </li>
                            <li><strong>Rectal Swab Collection Kit:</strong>
                                <ul>
                                    <li>Sterile swab (vaginal, rectal sample).</li>
                                    <li>Vial with transport solution or container to store the swab.</li>
                                    <li>Clear instructions for use.</li>
                                    <li>Transport tube containing transport medium.</li>
                                    <li>Label for patient identification.</li>
                                </ul>
                            </li>
                            <li><strong>Oral Swab Collection Kit:</strong>
                                <ul>
                                    <li>Oral swab with collection pad.</li>
                                    <li>Vial with transport solution or container to store the swab.</li>
                                    <li>Label for patient identification and sample.</li>
                                    <li>Clear instructions for use.</li>
                                </ul>
                            </li>
                        </ul>

                        <h3>HIV Testing - DBS Test HIV ½ Antigen/Antibody</h3>
                        <ul>
                            <li><strong>Fingerstick Blood Collection Kit:</strong>
                                <ul>
                                    <li>Sterile lancets for finger pricking.</li>
                                    <li>Blood collection card or small blood collection tube.</li>
                                    <li>Alcohol swabs for disinfection.</li>
                                    <li>Bandage.</li>
                                    <li>Desiccant pack if using a dried blood spot card.</li>
                                    <li>Biohazard bag with a zip-lock seal.</li>
                                </ul>
                            </li>
                        </ul>

                        <h3>Syphilis Testing (Blood Sample)</h3>
                        <ul>
                            <li><strong>Blood Collection Kit:</strong>
                                <ul>
                                    <li>Sterile lancets for finger pricking.</li>
                                    <li>Blood collection tube or dried blood spot card.</li>
                                    <li>Alcohol prep pads.</li>
                                    <li>Bandage.</li>
                                    <li>Desiccant pack if using a dried blood spot card.</li>
                                    <li>Biohazard bag with a zip-lock seal.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                );
            case 'kit3':
                return (
                    <div className="kit-section">
                        <h2>Kit 3 - Female Care</h2>
                        <p>At-home STI testing kits sample collection for gonorrhea, chlamydia & HIV.</p>

                        <h3>Gonorrhea and Chlamydia Testing Sample Collection (Urine, Oral Swab & Rectal Swab)</h3>
                        <ul>
                            <li><strong>Urine Collection Kit:</strong>
                                <ul>
                                    <li>Sterile urine collection cup with a secure, leak-proof lid.</li>
                                    <li>Urine transfer pipette or straw.</li>
                                    <li>Collection tube with preservative if needed.</li>
                                    <li>Absorbent pad to prevent leaks in case of spills.</li>
                                    <li>Biohazard bag with a zip-lock seal.</li>
                                </ul>
                            </li>
                            <li><strong>Rectal Swab Collection Kit:</strong>
                                <ul>
                                    <li>Sterile swab (vaginal, rectal sample).</li>
                                    <li>Vial with transport solution or container to store the swab.</li>
                                    <li>Clear instructions for use.</li>
                                    <li>Transport tube containing transport medium.</li>
                                    <li>Label for patient identification.</li>
                                </ul>
                            </li>
                            <li><strong>Oral Swab Collection Kit:</strong>
                                <ul>
                                    <li>Oral swab with collection pad.</li>
                                    <li>Vial with transport solution or container to store the swab.</li>
                                    <li>Label for patient identification and sample.</li>
                                    <li>Clear instructions for use.</li>
                                </ul>
                            </li>
                        </ul>

                        <h3>HIV Testing - DBS Test HIV ½ Antigen/Antibody</h3>
                        <ul>
                            <li><strong>Fingerstick Blood Collection Kit:</strong>
                                <ul>
                                    <li>Sterile lancets for finger pricking.</li>
                                    <li>Blood collection card or small blood collection tube.</li>
                                    <li>Alcohol swabs for disinfection.</li>
                                    <li>Bandage.</li>
                                    <li>Desiccant pack if using a dried blood spot card.</li>
                                    <li>Biohazard bag with a zip-lock seal.</li>
                                </ul>
                            </li>
                        </ul>

                        <h3>Additional Testing: Trichomoniasis, Mycoplasma, and Bacterial Vaginosis (BV)</h3>
                        <ul>
                            <li><strong>Trichomoniasis & Mycoplasma Genitalium Testing Vaginal Swab Collection Kit:</strong>
                                <ul>
                                    <li>Sterile swab for self-collection.</li>
                                    <li>Transport tube with specialized medium for PCR testing.</li>
                                    <li>Absorbent pad.</li>
                                    <li>Biohazard bag with a zip-lock seal.</li>
                                </ul>
                            </li>
                            <li><strong>Bacterial Vaginosis (BV) Testing Vaginal Swab Collection Kit:</strong>
                                <ul>
                                    <li>Sterile swab for collecting vaginal secretions.</li>
                                    <li>Transport tube with preservative for microscopic examination or nucleic acid testing.</li>
                                    <li>Absorbent pad.</li>
                                    <li>Biohazard bag with a zip-lock seal.</li>
                                    <li>pH test strip (optional).</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="patients-container">
            <div className="form-container">
                <h1>Kit Information</h1>
                <div className="kit-buttons">
                    <button onClick={() => setActiveKit('kit1')}>Kit 1</button>
                    <button onClick={() => setActiveKit('kit2')}>Kit 2</button>
                    <button onClick={() => setActiveKit('kit3')}>Kit 3</button>
                </div>
                <div className=' kits-container'>
                    {renderKitInfo()}
                </div>
            </div>
        </div>
    );
};

export default KitsInfo;
