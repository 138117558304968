import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchClinicProfile, updateClinicProfile } from '../../ApiHandler/ClinicFunctions';

const EditProfile = () => {
    const [formData, setFormData] = useState({
        email: '',
        phoneNumber: '',
        clinicName: '',
        street: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        patients: '',
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchClinicProfile(setFormData);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <ToastContainer />
            <form className="add-patient-form" onSubmit={(e) => updateClinicProfile(e, formData, setLoading)}>
                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter Your Email"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Phone No</label>
                    <input
                        type="phone"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        placeholder="Enter Your Phone Number"
                        required
                    />
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>Clinic Name</label>
                        <input
                            type="text"
                            name="clinicName"
                            value={formData.clinicName}
                            onChange={handleChange}
                            placeholder="Enter Your Clinic Name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Street</label>
                        <input
                            type="text"
                            name="street"
                            value={formData.street}
                            onChange={handleChange}
                            placeholder="Enter Your Clinic Address"
                            required
                        />
                    </div>
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>City</label>
                        <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            placeholder="Enter Your Clinic Address"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Postal Code</label>
                        <input
                            type="text"
                            name="postalCode"
                            value={formData.postalCode}
                            onChange={handleChange}
                            placeholder="Enter Your Clinic Address"
                            required
                        />
                    </div>
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>State</label>
                        <input
                            type="text"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            placeholder="Enter Your Clinic Address"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Country</label>
                        <input
                            type="text"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            placeholder="Enter Your Clinic Address"
                            required
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Estimate Number of Patients</label>
                    <select
                        name="patients"
                        value={formData.patients}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select</option>
                        <option value="1-50">1-50</option>
                        <option value="1-100">1-100</option>
                        <option value="1-500">1-500</option>
                        <option value="1-1000">1-1000</option>
                    </select>
                </div>
                <button type="submit" className="profile-save-button" disabled={loading}>
                        {loading ? 'Updating...' : 'Update'}
                    </button>
            </form>
        </>
    );
};

export default EditProfile;