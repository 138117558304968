const SupportInfo = () => {
 


  return (
    <>
    Support Info Component
    </>
  );
};

export default SupportInfo;